
import { Delegates } from "@/api";
import { CourseEvent, CourseEventWithPagination } from "@/api/types";
import Table from "@/components/common/Table.vue";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: { Table }
})
export default class CourseProgress extends Vue {
  @Prop({ required: true })
  delegateId!: number | string;

  filters = {
    name: "",
    date: ""
  };
  events: Array<CourseEvent> = [];
  eventsWithPagination: CourseEventWithPagination = {
    meta: {
      perPage: 12
    }
  };
  menu = false;
  page = 1;
  options = {};
  headers = [
    { text: "Course", value: "course" },
    { text: "Address", value: "address" },
    { text: "Date", value: "date" },
    { text: "Hours", value: "hours" }
  ];
  get formattedDate() {
    if (this.filters.date.length > 0) {
      return moment(this.filters.date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }
  @Watch("options", { deep: true })
  async optionChange(val: any) {
    await this.loadEventsData(val.page);
  }
  @Watch("filters", { deep: true })
  async onChangeFilters() {
    await this.loadEventsData(this.page);
  }
  onClearDate() {
    this.filters.date = "";
  }
  async loadEventsData(page = 1) {
    try {
      const eventsWithPagination = await Delegates.events(
        this.delegateId,
        page,
        this.filters
      );
      this.events = eventsWithPagination.data ? eventsWithPagination.data : [];
      this.events = this.events.map((item: CourseEvent) => {
        const dateArray = item.date?.split("-");
        if (dateArray) {
          const date = `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
          return { ...item, date };
        }
        return item;
      });
      this.eventsWithPagination = eventsWithPagination;
    } catch (e) {
      await this.$error(e.errors);
    }
  }
}
