
import { Certificates, Companies, Courses } from "@/api";
import { Certificate, CertificatesWithPagination } from "@/api/types";
import Table from "@/components/common/Table.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: { Table }
})
export default class Certifications extends Vue {
  @Prop({ required: true })
  delegate!: number | string;

  filters = {
    course: null,
    company: null
  };
  courses = [];
  companies = [];
  page = 1;
  certificates: Array<Certificate> = [];
  certificatesWithPagination: CertificatesWithPagination = {
    meta: {
      perPage: 12
    }
  };
  options = {};
  headers = [
    {
      text: "Certificates ID",
      value: "certificateId"
    },
    {
      text: "Course Name",
      value: "courseName"
    },
    {
      text: "Contract Type",
      value: "contractType"
    },
    {
      text: "Company Name",
      value: "companyName"
    },
    {
      text: "Date",
      value: "date"
    }
  ];
  @Watch("options", { deep: true })
  async onOptionsChange(val: any) {
    await this.loadCertificates(this.delegate, val.page);
  }

  @Watch("filters", { deep: true })
  async onFiltersChange() {
    await this.loadCertificates(this.delegate);
  }

  async created() {
    [this.courses, this.companies] = await Promise.all([
      Courses.listWithoutPagination(),
      Companies.listWithoutPagination()
    ]);
    await this.loadCertificates(this.delegate);
  }
  async loadCertificates(delegateId: number | string, page = 1) {
    try {
      const certificatesWithPagination = await Certificates.listByDelegate(
        delegateId,
        page,
        this.filters
      );
      this.certificates = certificatesWithPagination.data
        ? certificatesWithPagination.data
        : [];
      this.certificates = this.certificates.map((item: Certificate) => {
        const dateArray = item.date.split("-");
        if (dateArray.length > 0) {
          const date = `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
          return { ...item, date };
        }
        return item;
      });
      this.certificatesWithPagination = certificatesWithPagination;
    } catch (e) {
      await this.$error(e.errors);
    }
  }
  onDownload() {
    // console.log(': ',);
  }
}
