
import { Component, Vue } from "vue-property-decorator";

import { Companies, Delegates } from "@/api";
import { Company, Delegate } from "@/api/types";
import Card from "@/components/common/Card.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import Certifications from "@/components/delegates/Certifications.vue";
import CourseProgress from "@/components/delegates/CourseProgress.vue";
import DelegateDocuments from "@/components/delegates/DelegateDocuments.vue";
import moment from "moment";

@Component({
  components: {
    Certifications,
    CourseProgress,
    Card,
    PageTitle,
    DelegateDocuments
  }
})
export default class DelegatesViewPage extends Vue {
  delegate: Delegate = {
    delegateEvent: [],
    currentEvent: {}
  };
  company: Company = {};
  tab = null;
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Delete",
      icon: "mdi-delete",
      color: "gray",
      action: "delete"
    },
    {
      text: "Edit",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "edit"
    }
  ];
  headersTitle = [{ icon: "mdi-account", header: "" }];
  get delegateId() {
    return this.$route.params.id;
  }
  get keyDetails() {
    return [
      { title: "Cell Phone Number", content: this.delegate.cellPhone || "-" },
      { title: "Home Phone Number", content: this.delegate.phone || "-" },
      { title: "Gender", content: this.delegate.gender?.name },
      {
        title: "Date Of Birth",
        content: moment(this.delegate.birthday, "DD-MM-YYYY").format(
          "DD/MM/YYYY"
        )
      },
      { title: "Equity Code", content: this.delegate.equityCode || "-" },
      {
        title: "Nationality Code",
        content: this.delegate.nationalityCode || "-"
      },
      { title: "Alternative ID", content: this.delegate.alternativeId || "-" },
      {
        title: "Alternative Type",
        content: this.delegate.alternativeType || "-"
      },
      {
        title: "Citizen Residential Status Code",
        content: this.delegate.residentialStatusCode || "-"
      },
      {
        title: "Home Language Code",
        content: this.delegate.homeLanguageCode?.length
          ? this.delegate.homeLanguageCode?.join(", ")
          : "-"
      },
      {
        title: "Disability Code",
        content: this.delegate.disabilityCode || "-"
      },
      { title: "Home Address", content: this.delegate.homeAddress || "-" },
      { title: "Province Code", content: this.delegate.provinceCode || "-" },
      {
        title: "Home Address Postal Code",
        content: this.delegate.postalAddressPostalCode || "-"
      },
      { title: "Email Address", content: this.delegate.email || "-" },
      {
        title: "Driver’s License Code",
        content: this.delegate.driversLicenseCode || "-"
      },
      {
        title: "Has Access to VAULT",
        content: this.delegate.vaultAccess ? "+" : "-"
      },
      {
        title: "Can Download Results",
        content: this.delegate.watchSelfResults ? "+" : "-"
      }
      // { title: "PrDP Categories", content: this.delegate.prdpCategory || "-" },
    ];
  }
  get companyInfo() {
    return [
      { title: "Company Name", content: this.company.name },
      {
        title: "Contact Person",
        content: this.company.representativeName
      },
      {
        title: "Representative Email",
        content: this.company.representativeEmail
      },
      {
        title: "Representative Phone",
        content: this.company.representativePhone
      },
      { title: "Address", content: this.company.address }
    ];
  }
  async onBack() {
    await this.$router.push({ name: "DelegatesList" });
  }
  async created() {
    try {
      this.delegate = await Delegates.info(this.delegateId);
      this.headersTitle[0].header = `${this.delegate.title || ""} ${
        this.delegate.name
      } ${this.delegate.surname}`;
      this.company = await Companies.info(this.delegate.company?.id);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async onDelete() {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await Delegates.remove(this.delegateId);
        await this.onBack();
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors);
      }
    }
  }
  async onEdit() {
    await this.$router.push({
      name: "DelegatesEdit",
      params: { id: this.delegateId }
    });
  }
}
